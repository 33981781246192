export default function(value) {
  if (value) {
    let array = value.toString().split(",");
    let newValue = array.join("");
    value = newValue;
  }

  if (isNaN(value)) {
    return value;
  }

  let formatter = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 4
  });

  return formatter.format(value);
}
