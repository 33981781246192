export const EnquadramentoEnum = {
  "ENQUADRADO": 1,
  "NENHUM": 0,
  "REPROVADO": -1,
};

export const StatusProjetoEnum = {
  "PENDENTE_ENVIO_ANALISE": 1,
  "PENDENTE_ANALISE": 2,
  "ATRASO": 3,
  "INFORMACOES_PENDENTES": 4,
  "NAO_ENQUADRADO": 5,
  "ELABORACAO_DESCRITIVO": 6,
  "REVISAO_GALAPOS": 8,
  "CORRECAO_DESCRITIVO": 9,
  "VALIDACAO_FINAL_CLIENTE": 11,
  "VALIDACAO_FINAL_GALAPOS": 12,
  "APROVADO_SUBMISSAO": 13,
  "SUBMETIDO": 14,
};
