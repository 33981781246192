<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { EnquadramentoEnum } from "@/core/enums/projetos";
import toDouble from "@/helpers/toDouble";

export default {
  components: {
    AutoAjuste: () => import("./ajuste-automatico.vue"),
    AjusteModal: () => import("./ajuste-modal.vue"),
    MasterDetail: () => import("@/components/master-detail.vue"),
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalData: {},
      modalEditMode: false,
      modalOpened: false,
      seeAllFooter: false,
    };
  },
  computed: {
    ...mapGetters(["clientId", "intervaloCompetencia"]),
    actionBarButtons() {
      return [
        {
          text: "Voltar",
          icon: "mdi-arrow-left",
          action: () => {
            this.$emit("back");
          },
        },
        {
          text: "Atualizar",
          icon: "mdi-refresh",
          action: () => {
            this.reload();
          },
        },
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.modalEditMode = false;
            this.modalData = this.formatModalData();
            this.adjustmentModalErrorMessage = "";
            this.modalOpened = true;
          },
        },
        {
          text: "Ajuste automático",
          icon: "mdi-clock-star-four-points-outline",
          action: () => {
            this.$refs.autoAjuste.openHandler();
          },
        },
      ];
    },
    columns() {
      const defaultCols = [
        {
          key: "tituloFormatado",
          name: "Título do projeto",
          type: this.$fieldTypes.HTML,
          withoutMargin: true,
        },
        {
          key: "data_ini",
          name: "Data inicial",
        },
        {
          key: "data_fim",
          name: "Data final",
        },
      ];
      const FORMAT_STRING = "MM/YYYY";
      const PARSE_STRING = "YYYY-MM";
      const [competenciaIni, competenciaFim] = this.intervaloCompetencia;
      const competenciaLength =
        1 +
        moment(competenciaFim, PARSE_STRING).diff(
          moment(competenciaIni, PARSE_STRING),
          "months"
        );
      const competenciaCols = Array.from({ length: competenciaLength }).map(
        (_, index) => {
          const date = moment(competenciaIni, PARSE_STRING).add(index, "month");
          return {
            key: date.format(PARSE_STRING),
            name: date.format(FORMAT_STRING),
            type: this.$fieldTypes.HTML,
            align: 1,
          };
        }
      );

      return defaultCols.concat(competenciaCols);
    },
    contextOptions() {
      return [
        {
          name: "Ajustar horas",
          cb: (row, col) => {
            this.modalEditMode = true;
            this.modalData = this.formatModalData(row, col);
            this.adjustmentModalErrorMessage = "";
            this.modalOpened = true;
          },
        },
      ];
    },
    customResource() {
      const resource = this.apiResource(
        `v1/timesheet/${this.clientId}/conferenciaProjetos`
      );
      const formatProjectTitle = this.formatProjectTitle;
      const formatHours = this.formatHours;
      const toDate = this.$options.filters.toDate;
      return {
        ...resource,
        get(...arg) {
          return resource.get(...arg).then((response) => {
            if (!("projetos" in response && "horas_totais" in response)) {
              return [];
            }

            const projects = !Array.isArray(response.projetos)
              ? []
              : response.projetos.map(
                  ({
                    data_ini,
                    data_fim,
                    enquadramento,
                    horas,
                    titulo,
                    ...rest
                  }) => ({
                    ...rest,
                    ...Object.entries(horas).reduce((acc, [key, value]) => ({ ...acc, [key]: formatHours(value) }), {}),
                    data_ini: toDate(data_ini) ?? "Indefinido",
                    data_fim: toDate(data_fim) ?? "Indefinido",
                    titulo,
                    tituloFormatado: formatProjectTitle(titulo, enquadramento),
                  })
                );
            const totalsConfig = {
              horas_trabalhadas: {
                label: "Horas Trabalhadas",
                order: 0,
              },
              timesheets_importados: {
                label: "Total timesheet (importados)",
                order: 1,
              },
              diferenca_total: {
                label: "Diferença",
                order: 2,
              },
              horas_ped: {
                label: "Horas em P&D (calculado)",
                order: 3,
              },
              timesheets_elegiveis: {
                label: "Horas timesheet (elegíveis)",
                order: 4,
              },
              diferenca_elegivel: {
                label: "Diferença em P&D (elegíveis)",
                order: 5,
              },
            };
            const totals = Object.entries(response.horas_totais)
              .filter(([key]) => key in totalsConfig)
              .sort(
                ([keyA], [keyB]) =>
                  totalsConfig[keyA].order - totalsConfig[keyB].order
              )
              .map(([key, value]) => ({
                tituloFormatado: formatProjectTitle(
                  totalsConfig[key].label,
                  false
                ),
                ...Object.entries(value).reduce((acc, [key, value]) => ({ ...acc, [key]: formatHours(value) }), {}),
              }));

            return projects.concat(totals);
          });
        },
      };
    },
    filterQuery() {
      return `funcionarioId=${this.data?.id}`;
    },
    title() {
      return `Projetos — ${this.data?.nome || "Colaborador indefinido"}`;
    },
  },
  methods: {
    formatModalData(row, col) {
      return {
        competencia: (!row && !col) ? null : col?.value && moment(col.value, 'YYYY-MM').isValid() ? col?.value : this.intervaloCompetencia[0],
        funcionarioId: this.data?.id,
        funcionarioNome: this.data?.nome || "Colaborador indefinido",
        titulo: row?.titulo,
        projetoId: row?.id,
        subtrair: false,
        horas: 0,
      };
    },
    formatHours(cellValue) {
      const isNegative = cellValue < 0;

      return `
        <span${isNegative ? ' class="text-red"' : ''}>${toDouble(cellValue)}</span>
      `;
    },
    formatProjectTitle(titulo, enquadramento) {
      let projectMarkClass = "";
      let projectTooltip = "";

      switch (enquadramento) {
        case EnquadramentoEnum.ENQUADRADO:
          projectMarkClass = "enquadrado";
          projectTooltip = "Enquadrado";
          break;
        case EnquadramentoEnum.NENHUM:
          projectMarkClass = "nenhum";
          projectTooltip = "Não enquadrado";
          break;
        case EnquadramentoEnum.REPROVADO:
          projectMarkClass = "reprovado";
          projectTooltip = "Reprovado";
          break;
        default:
          projectMarkClass = "none";
          projectTooltip = "";
      }

      return `
        <div class="project-title text-truncate" title="${projectTooltip} — ${titulo}">
          <span class="enquadramento-mark ${projectMarkClass}"></span>
          ${titulo}
        </div>
      `;
    },
    reload() {
      this.$refs.projetosTable?.doLoad();
    }
  },
};
</script>

<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      {{ title }}
    </v-card-title>

    <MasterDetail
      ref="projetosTable"
      :actionBarButtons="actionBarButtons"
      :canDelete="false"
      :canEdit="false"
      :class="{ 'hide-some-footer': !seeAllFooter }"
      :cols="columns"
      :contextOptions="contextOptions"
      :customResource="customResource"
      disableActContextOnClick
      disablePagination
      :filterQuery="filterQuery"
      hasBeneficioFilter
      :hasExportCSV="false"
      :hasExportXLSX="false"
      hasMonthlyFilter
      :hasNewButton="false"
    >
      <v-switch
        v-model="seeAllFooter"
        class="see-all-footer"
        hide-details
        :label="seeAllFooter ? 'Completo' : 'Simplificado'"
      />
    </MasterDetail>

    <AutoAjuste ref="autoAjuste" :funcionarioId="data.id" @reload="reload" />
    <AjusteModal :data.sync="modalData" :editMode="modalEditMode" :opened.sync="modalOpened" @reload="reload" />
  </v-card>
</template>

<style lang="scss" scoped>
$neutral-background: #fff;
$light-background: #bedefe;
$dark-background: #81b4ea;

$negative-value: #ec2020;

::v-deep {
  .project-title {
    max-width: 400px;

    .enquadramento-mark {
      border-radius: 50%;
      display: inline-block;
      height: 10px;
      width: 10px;

      &.none {
        display: none;
      }

      &.enquadrado {
        background-color: green;
      }

      &.nenhum {
        background-color: gray;
      }

      &.reprovado {
        background-color: $negative-value;
      }
    }
  }

  .text-red {
    color: $negative-value;
    font-weight: bold;
  }

  .v-data-table__wrapper table {
    thead tr th:first-child {
      left: 0;
      position: sticky;
      z-index: 3;
    }

    tbody tr:not(.v-data-table__empty-wrapper) {
      cursor: auto;

      td {
        height: 36px;

        &:first-child {
          background-color: $neutral-background;
          left: 0;
          position: sticky;
        }
      }

      &:nth-last-child(-n + 6) {
        bottom: 0;
        position: sticky;

        &:nth-child(2n + 0) {
          &,
          & td {
            background-color: $light-background;
          }

          td {
            border-bottom-color: $light-background !important;
          }

          &.table-v-tr:hover {
            background-color: $light-background !important;
          }
        }

        &:nth-child(2n-1) {
          &,
          & td {
            background-color: $dark-background;
          }

          td {
            border-bottom-color: $dark-background !important;
          }

          &.table-v-tr:hover {
            background-color: $dark-background !important;
          }
        }
      }

      @for $i from 1 through 6 {
        &:nth-last-child(#{$i}) {
          bottom: calc(35px * (#{$i} - 1));
        }
      }
    }
  }
}

.hide-some-footer::v-deep
  .v-data-table__wrapper
  table
  tbody
  tr:not(.v-data-table__empty-wrapper) {
  @for $i from 4 through 6 {
    &:nth-last-child(#{$i}) {
      display: none;
    }
  }
}

.v-input.v-input--switch.see-all-footer {
  margin: 0px;
  width: 127px;
}
</style>
